// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BAW3SB39lASXeUUK3WCf .yHPD6Gf3oETLvfhvKoXX{align-items:center;margin:auto;padding:0 0 60px}.BAW3SB39lASXeUUK3WCf .AqKsn4bRS9leoVsRzPln{display:flex;justify-content:center;align-items:center;flex-direction:column;min-height:calc(100vh - 16px)}.BAW3SB39lASXeUUK3WCf .AqKsn4bRS9leoVsRzPln div[role=progressbar]{width:50px;height:50px}.BAW3SB39lASXeUUK3WCf .vQDzlIsk2bXrGDcJWWLT{display:block}", "",{"version":3,"sources":["webpack://./src/pages/Completed.module.scss"],"names":[],"mappings":"AACC,4CACC,kBAAA,CACA,WAAA,CACA,gBAAA,CAGD,4CACC,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,qBAAA,CACA,6BAAA,CAEA,kEACC,UAAA,CACA,WAAA,CAIF,4CACC,aAAA","sourcesContent":[".completed {\n\t.spinner {\n\t\talign-items: center;\n\t\tmargin: auto;\n\t\tpadding: 0 0 60px;\n\t}\n\n\t.progressBar {\n\t\tdisplay: flex;\n\t\tjustify-content: center;\n\t\talign-items: center;\n\t\tflex-direction: column;\n\t\tmin-height: calc(100vh - 2 * 8px);\n\n\t\tdiv[role='progressbar'] {\n\t\t\twidth: 50px;\n\t\t\theight: 50px;\n\t\t}\n\t}\n\n\t.label {\n\t\tdisplay: block;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"completed": "BAW3SB39lASXeUUK3WCf",
	"spinner": "yHPD6Gf3oETLvfhvKoXX",
	"progressBar": "AqKsn4bRS9leoVsRzPln",
	"label": "vQDzlIsk2bXrGDcJWWLT"
};
export default ___CSS_LOADER_EXPORT___;
